import styles from './PeriodChart.module.sass';

export const PeriodChart = () => {
  const years = [
    '6 мес',
    '9 мес',
    '1 год',
    '1,5 года',
    '2 года',
    '3 года',
  ];

  return (
    <div className={styles.columns}>
      {years.map((year) => (
        <div key={year} className={styles.column}>
          <div className={styles.description}>{year}</div>
          <div className={styles.block} />
        </div>
      ))}
    </div>
  );
};
