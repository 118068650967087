import dayjs from 'dayjs';
import {pluralize} from '../pluralize';

export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_VIEW = 'DD.MM.YYYY';

export const formatDate = (value) => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  return dayjs(value).format(DATE_FORMAT_VIEW);
};

export const getDateDiff = (value) => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  const yearPluralizeMap = ['год', 'года', 'лет'];
  const year = Math.abs(dayjs(value).diff(dayjs(), 'year'));
  if (year) {
    const month = Math.abs(dayjs(value).subtract(year, 'year').diff(dayjs(), 'month'));
    if (month) {
      return `${year} ${pluralize(year, yearPluralizeMap)} ${month} мес`;
    }
    return `${year} ${pluralize(year, yearPluralizeMap)}`;
  }
  return `${Math.abs(dayjs(value).diff(dayjs(), 'month'))} мес`;
};
