import Slider from '@mui/material/Slider';
import {formatAmount} from '../../../../../modules/format';
import styles from '../Filter/styles.module.sass';

export const DepositFilter = ({
  title,
  chart,
  value,
  minValue,
  maxValue,
  onChange,
  disabled,
  usePlus,
}) => (
  <div className={styles.filter}>
    <div className={styles.title}>{title}</div>
    <div className={styles.chart}>{chart}</div>
    <Slider
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      min={minValue}
      max={maxValue}
      valueLabelDisplay={'auto'}
      valueLabelFormat={(value) => {
        return formatAmount(value, usePlus);
      }}
    />
  </div>
);
