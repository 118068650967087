import {useMemo} from 'react';
import lodash from 'lodash';
import styles from './PeriodStatus.module.sass';

export const PERIOD = {
  '3Y': {
    from: '2 лет 5 мес',
    to: '3 лет 5 мес',
  },
  '2Y': {
    from: '1 года 9 мес',
    to: '2 лет 5 мес',
  },
  '1.5Y': {
    from: '1 года 4 мес',
    to: '1 года 9 мес',
  },
  '1Y': {
    from: '10 мес',
    to: '1 года 4 мес',
  },
  '9M': {
    from: '7 мес',
    to: '10 мес',
  },
  '6M': {
    from: '4 мес',
    to: '7 мес',
  },
};

export const PERIOD_FULL = {
  '3Y': {
    from: '2 года 5 месяцев',
    to: '3 года 5 месяцев',
  },
  '2Y': {
    from: '1 год 9 месяцев',
    to: '2 года 5 месяцев',
  },
  '1.5Y': {
    from: '1 год 4 месяца',
    to: '1 год 9 месяцев',
  },
  '1Y': {
    from: '10 месяцев',
    to: '1 год 4 месяца',
  },
  '9M': {
    from: '7 месяцев',
    to: '10 месяцев',
  },
  '6M': {
    from: '4 месяцев',
    to: '7 месяцев',
  },
};

export const PeriodStatus = ({periods}) => {
  const from = useMemo(() => PERIOD[periods[0]]?.from || '-', [periods]);
  const to = useMemo(() => PERIOD[lodash.last(periods)]?.to || '-', [periods]);

  return (
    <div className={styles.container}>
      <span>от</span> {from} <span>до</span> {to}
    </div>
  );
};
