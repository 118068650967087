import {request} from '../data';

export class Api {
  constructor (httpRequest = request) {
    this._httpRequest = httpRequest;
  }

  _request ({method, path, query, headers = {}, body = null, signal}) {
    const requestHeaders = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'X-REQUEST-CLIENT-TYPE': 'web',
      'X-REQUEST-CLIENT-VERSION': '1.0',
      'X-REQUEST-ID': Date.now(),
      ...headers
    };

    return this._httpRequest({
      method,
      url: path,
      query,
      headers: requestHeaders,
      body,
      signal
    });
  }

  _get (requestParams) {
    return this._request({
      ...requestParams,
      method: 'GET',
      body: null
    });
  }

  _post (requestParams) {
    return this._request({
      ...requestParams,
      method: 'POST'
    });
  }

  filterBonds (token, totalAmount, term, termStart, termEnd, taxRate, ratings, signal) {
    return this._post({
      headers: {'X-Api-Access': token},
      path: 'https://skybonds.com/api/v3/newton/adviser/moex/bonds/filter',
      body: {
        'totalAmount': totalAmount,
        'term': term,
        'minTermOffset': termStart,
        'maxTermOffset': termEnd,
        'taxRate': taxRate,
        'strategy': ['maturity', 'option_put'],
        filters: [
          {name: "actual", value: ["true"]},
          {name: 'dom-int', value: ['domestic']},
          {name: 'nationalRatingScale', value: ['RU']},
          {name: "nationalRating", value: ratings},
          {name: "currency", value: ["RUB"]},
          {name: "regular", value: ["true"]},
          {name: "subord", value: ["false"]},
          {name: "floater", value: ["false"]},
          {name: "convertible", value: ["false"]},
          {name: "perpetual", value: ["false"]},
          {name: "structured", value: ["false"]},
          {name: "zeroCoupon", value: ["false"]},
        ]
      },
      signal,
    });
  }

  getSummary () {
    return this._get({path: 'https://skybonds.com/api/v1/data/summary/'});
  }

  getCalendars (token, signal, year) {
    return this._get({
      path: `https://skybonds.com/api/v1/data/calendars/MOEX%2FSTOCK/${year}`,
      signal,
    });
  }

  getBondsDaily (token, date, isins, attrs = null) {
    return this._post({
      path: `https://skybonds.com/api/v1/data/bonds/daily/${date}`,
      body: isins,
      query: attrs
        ? {attrs}
        : '',
    });
  }

  getBondsInfo (token, isins, attrs = null) {
    return this._post({
      path: 'https://skybonds.com/api/v1/data/bonds/info',
      body: isins,
      query: attrs
        ? {attrs}
        : '',
    });
  }
}
