import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {InputAdornment, MenuItem, OutlinedInput, TextField, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import classNames from 'classnames';
import lodash from 'lodash';
import {PERIODS, PERIODS_RU, RATES_ALFA} from '../../../../modules/Periods';
import {RATINGS, getRatingStatus} from '../../../../modules/Ratings';
import {CustomNumericFormat} from '../../common/CustomNumericFormat';
import {Filter} from './Filter';
import {DepositFilter} from './DepositFilter';
import {DepositChart, PeriodChart, RatingChart} from './Filter/Charts';
import {PeriodStatus} from './Filter/PeriodStatus';
import LoadingSpinner from './LoadingSpinner';
import {Suggestions} from './Suggestions';
import {getColorScale} from '../../../../modules/ColorScale';
// import mockData from './mockData.json';
import styles from './styles.module.sass';
import './common.sass';

const TOTAL_AMOUNT_LIMIT = 100000000; // 100млн

const TAX_RATES = [
  {label: 'Не учитывать', value: 0},
  {label: '13%', value: 0.13},
  {label: '15%', value: 0.15},
];

export const MEASURED_VALUES_MAP = {
  INCOME: 'income',
  DIFFERENCE_WITH_DEPOSIT: 'differenceWithDeposit',
};

export const MEASURED_VALUES = [
  {label: 'Основной', value: MEASURED_VALUES_MAP.INCOME},
  {label: 'Разница с депозитом', value: MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT},
];

export const getExtremums = (data, measuredValue) => {
  let min = Infinity;
  let max = -Infinity;
  lodash.forEach(data, (periods) => {
    lodash.forEach(periods, (bonds) => {
      bonds.forEach((bond) => {
        min = Math.min(min, bond[measuredValue]);
        max = Math.max(max, bond[measuredValue]);
      });
    });
  });
  return [min, max];
};

export const getMeasuredValues = (data, measuredValue) => {
  const values = [];
  lodash.forEach(data, (periods) => {
    lodash.forEach(periods, (bonds) => {
      bonds.forEach((bond) => {
        values.push(bond[measuredValue]);
      });
    });
  });
  return values;
};

export const getDepositsByPeriods = (totalAmount, taxRate) => Object.keys(RATES_ALFA).reduce((acc, key) => {
  const [term, rate] = RATES_ALFA[key];
  acc[key] = ((rate / 100) / 365) * term * totalAmount * (1 - taxRate);
  return acc;
}, {});

export const prepareData = (bondsData, totalAmount, depositsByPeriods, taxRate) => {
  if (!bondsData || Object.keys(bondsData).length === 0) {
    return {};
  }
  return RATINGS.reduce((accI, rating) => {
    accI[rating] = PERIODS.reduce((accJ, period, j) => {
      accJ[period] = bondsData[rating][period].map((bond) => {
        const differenceWithDeposit = bond.return.amountReceivedAfterTax - totalAmount - depositsByPeriods[period];
        return {
          isin: bond.bond.isin,
          issuerName: bond.bond.issuer,
          issueNumber: bond.bond.issueNumber,
          income: bond.return.amountReceivedAfterTax - totalAmount,
          differenceWithDeposit,
          // yield: bond.transactions[0].yield * 100 * (1 - taxRate),
          yield: bond.return.annualizedTotalReturnAfterTax * 100,
          pricePercent: differenceWithDeposit / totalAmount * 100,
          totalResult: bond.return.amountReceivedAfterTax,
          sector: bond.bond.sector,
          nationalRating: bond.bond.nationalRating,
          ratingGroup: rating,
          redemptionDate: bond.transactions[1].date,
          depositYield: RATES_ALFA[period][1] * (1 - taxRate),
          depositTotal: totalAmount + depositsByPeriods[period],
          buyPrice: bond.transactions[0].price,
          buyQuantity: bond.transactions[0].quantity,
          period,
          periodLabel: PERIODS_RU[j],
          proposalType: bond.transactions[1].type,
        };
      });
      accJ[period] = accJ[period].sort((a, b) => b.income - a.income);
      return accJ;
    }, {});
    return accI;
  }, {})
}

export const filterData = (data, ratingFilter, periodFilter, measuredValueFilter, measuredValue) => {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }
  return RATINGS.slice(ratingFilter[0], ratingFilter[1]).reduce((accI, rating) => {
    accI[rating] = PERIODS.slice(periodFilter[0], periodFilter[1]).reduce((accJ, period) => {
      accJ[period] = data[rating][period].filter(
        (item) => item[measuredValue] >= measuredValueFilter[0] && item[measuredValue] <= measuredValueFilter[1]
      );
      return accJ;
    }, {});
    return accI;
  }, {})
}

export const HomeController = ({
  bondsData,
  pending,
  error,
  totalAmount,
  taxRate,
  onTotalAmountChange,
  onTaxRateChange,
}) => {
  const depositsByPeriods = useMemo(
    () => getDepositsByPeriods(totalAmount, taxRate),
    [totalAmount, taxRate]
  );
  const data = useMemo(
    () => prepareData(bondsData, totalAmount, depositsByPeriods, taxRate),
    [bondsData, totalAmount, taxRate]
  );

  const [measuredValue, setMeasuredValue] = useState(MEASURED_VALUES_MAP.INCOME);
  const [ratingFilter, setRatingFilter] = useState([4, 8]);
  const [periodFilter, setPeriodFilter] = useState([0, 6]);

  const [minMeasuredValue, maxMeasuredValue] = useMemo(() => getExtremums(data, measuredValue), [data, measuredValue]);
  const [measuredValueFilter, setMeasuredValueFilter] = useState([0, maxMeasuredValue]);
  const [totalAmountFromInput, setTotalAmountFromInput] = useState(totalAmount);

  const filteredData = useMemo(() => filterData(data, ratingFilter, periodFilter, measuredValueFilter, measuredValue),
    [ratingFilter, periodFilter, measuredValueFilter, measuredValue, data]
  );
  const measuredValues = useMemo(() => getMeasuredValues(filteredData, measuredValue), [filteredData, measuredValue]);
  const colorScale = getColorScale(measuredValues);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTotalAmountChange = useCallback(lodash.debounce(onTotalAmountChange, 400), []);
  useEffect(() => {
    const newValue = totalAmountFromInput && totalAmountFromInput > TOTAL_AMOUNT_LIMIT ? TOTAL_AMOUNT_LIMIT : totalAmountFromInput;
    setTotalAmountFromInput(newValue);
    handleTotalAmountChange(newValue);
  }, [totalAmountFromInput, handleTotalAmountChange]);
  useEffect(() => {
    setMeasuredValueFilter([0, maxMeasuredValue]);
  }, [minMeasuredValue, maxMeasuredValue]);

  return (
    <div className={styles.page}>
      <div className={styles.params}>
        <div className={styles.param}>
          <div className={styles.paramLabel}>Сумма инвестиций:</div>
          <div className={classNames(styles.paramField, styles.__input)}>
            <OutlinedInput
              value={totalAmountFromInput}
              onChange={(e) => setTotalAmountFromInput(e.target.value)}
              size="small"
              endAdornment={<InputAdornment position="end">₽</InputAdornment>}
              inputComponent={CustomNumericFormat}
            />
          </div>
        </div>
        <div className={styles.param}>
          <div className={styles.paramLabel}>Налог:</div>
          <div className={styles.paramField}>
            <TextField
              onChange={(e) => onTaxRateChange(e.target.value)}
              value={taxRate}
              select
              size="small"
            >
              {TAX_RATES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={styles.filterHelpIcon}>
            <Tooltip title={(<div className={styles.filterTooltipContent}>
              Ставка налога применяется для Облигаций и Депозита на сумму полученного дохода, без учета деталей по налогообложению. Сумма результата уже за вычетом налога.
            </div>)}>
              <HelpIcon />
            </Tooltip>
          </div>
        </div>
        <div className={styles.param}>
          <div className={styles.paramLabel}>Доход:</div>
          <div className={styles.paramField}>
            <TextField
              onChange={(e) => setMeasuredValue(e.target.value)}
              value={measuredValue}
              select
              size="small"
            >
              {MEASURED_VALUES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={styles.filterHelpIcon}>
            <Tooltip title={<div className={styles.filterTooltipContent}>
              <div className={styles.filterTooltipBlock}>
                <div className={styles.filterTooltipTitle}>
                  Основной доход
                </div>
                <div className={styles.filterTooltipText}>
                  Доход облигации без сравнения
                </div>
              </div>
              <div className={styles.filterTooltipTitle}>
                Разница с депозитом
              </div>
              <div className={styles.filterTooltipText}>
                Сравнение основного дохода по облигации с доходом по депозиту за похожий срок
              </div>

              <div className={styles.filterTooltipTable}>
                Депозиты (сроки и доходность):
                <table>
                  <tbody>
                  {PERIODS.map((period, i) => (
                    <tr key={period}>
                      <td>{PERIODS_RU[i]}</td>
                      <td>{RATES_ALFA[period][1]}%</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>}>
              <HelpIcon />
            </Tooltip>
          </div>
        </div>
      </div>

      <div className={styles.filters}>
        <DepositFilter
          title={(<>
            1. Величина дохода
            <div className={styles.filterHelpIcon}>
              <Tooltip title={
                <div className={styles.filterTooltipContent}>
                  <div className={styles.filterTooltipText}>
                    Выгода больше при длительных сроках и меньшей надежности.
                  </div>
                </div>}>
                <HelpIcon />
              </Tooltip>
            </div>
          </>)}
          disabled={!isFinite(minMeasuredValue) || !isFinite(maxMeasuredValue)}
          chart={(<DepositChart
            totalMin={minMeasuredValue}
            totalMax={maxMeasuredValue}
            usePlus={measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT}
          />)}
          onChange={setMeasuredValueFilter}
          value={measuredValueFilter}
          minValue={minMeasuredValue}
          maxValue={maxMeasuredValue}
          usePlus={measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT}
        />
        <Filter
          title={(<>
            2. Уровень риска
            <div className={styles.filterHelpIcon}>
              <Tooltip title={<div className={styles.filterTooltipContent}>
                <div className={styles.filterTooltipBlock}>
                  <div className={styles.filterTooltipTitle}>
                    Низкий риск
                  </div>
                  <div className={styles.filterTooltipText}>
                    Облигации покупают пенсионные фонды для хранения и приумножения пенсионных отчислений
                  </div>
                </div>
                <div className={styles.filterTooltipTitle}>
                  Высокий риск
                </div>
                <div className={styles.filterTooltipText}>
                  Облигации с недостаточно высоким уровнем надежности. Только для опытных инвесторов
                </div>
              </div>}>
                <HelpIcon />
              </Tooltip>
            </div>
          </>)}
          sliderValue={getRatingStatus(RATINGS.slice(...ratingFilter))}
          chart={(<RatingChart />)}
          value={ratingFilter}
          onChange={setRatingFilter}
          minValue={0}
          maxValue={8}
        />
        <Filter
          title="3. Срок инвестиций"
          sliderValue={<PeriodStatus periods={PERIODS.slice(...periodFilter)} />}
          chart={(<PeriodChart />)}
          value={periodFilter}
          onChange={setPeriodFilter}
          minValue={0}
          maxValue={6}
        />
      </div>

      {Boolean(totalAmount) === false && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_caption}>
            {'Введите значение больше 0'}
          </div>
        </div>
      )}
      {Boolean(totalAmount) !== false && error && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_error}>
            {'Ошибка загрузки данных'}
          </div>
        </div>
      )}
      {pending && (
        <div className={styles.messageCover}>
          <div className={styles.messageCover_caption}>
            {'Загрузка'}
          </div>
          <LoadingSpinner />
        </div>
      )}
      {!pending && !error && (<Suggestions
        data={filteredData}
        colorScale={colorScale}
        measuredValue={measuredValue}
      />)}
    </div>
  );
};
