import React from 'react';
import {InputAdornment, MenuItem, OutlinedInput, TextField, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import classNames from 'classnames';
// import {PERIODS, PERIODS_RU, RATES_ALFA} from '../../../../../modules/Periods';
import {CustomNumericFormat} from '../../../common/CustomNumericFormat';
import styles from './Params.module.sass';
import homeStyles from '../styles.module.sass';

const TAX_RATES = [
  {label: 'Не учитывать', value: 0},
  {label: '13%', value: 0.13},
  {label: '15%', value: 0.15},
];

export const MEASURED_VALUES_MAP = {
  INCOME: 'income',
  DIFFERENCE_WITH_DEPOSIT: 'differenceWithDeposit',
};

export const MEASURED_VALUES = [
  {label: 'Основной', value: MEASURED_VALUES_MAP.INCOME},
  {label: 'Разница с депозитом', value: MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT},
];

export const Params = ({
  totalAmountFromInput,
  setTotalAmountFromInput,
  taxRate,
  onTaxRateChange,
  measuredValue,
  setMeasuredValue,
}) => {
  return (
    <div className={styles.params}>
      <div className={styles.param}>
        <div className={styles.paramLabel}>Сумма инвестиций:</div>
        <div className={classNames(styles.paramField, styles.__input)}>
          <OutlinedInput
            value={totalAmountFromInput}
            onChange={(e) => setTotalAmountFromInput(e.target.value)}
            size="small"
            endAdornment={<InputAdornment position="end"><CurrencyRubleIcon /></InputAdornment>}
            inputComponent={CustomNumericFormat}
          />
        </div>
      </div>

      <div className={styles.param}>
        <div className={styles.paramLabel}>Налог:</div>
        <div className={styles.paramField}>
          <TextField
            onChange={(e) => onTaxRateChange(e.target.value)}
            value={taxRate}
            select
            size="small"
          >
            {TAX_RATES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={homeStyles.helpIcon}>
          <Tooltip title={(<div className={homeStyles.tooltipContent}>
            Ставка налога применяется для Облигаций и Депозита на сумму полученного дохода, без учета деталей по налогообложению. Сумма результата уже за вычетом налога.
          </div>)}>
            <HelpIcon />
          </Tooltip>
        </div>
      </div>

      {/** NB: временно скрываем  */}
      {/*<div className={styles.param}>*/}
      {/*  <div className={styles.paramLabel}>Доход:</div>*/}
      {/*  <div className={styles.paramField}>*/}
      {/*    <TextField*/}
      {/*      onChange={(e) => setMeasuredValue(e.target.value)}*/}
      {/*      value={measuredValue}*/}
      {/*      select*/}
      {/*      size="small"*/}
      {/*    >*/}
      {/*      {MEASURED_VALUES.map((option) => (*/}
      {/*        <MenuItem key={option.value} value={option.value}>*/}
      {/*          {option.label}*/}
      {/*        </MenuItem>*/}
      {/*      ))}*/}
      {/*    </TextField>*/}
      {/*  </div>*/}
      {/*  <div className={homeStyles.helpIcon}>*/}
      {/*    <Tooltip title={<div className={homeStyles.tooltipContent}>*/}
      {/*      <div className={homeStyles.tooltipBlock}>*/}
      {/*        <div className={homeStyles.tooltipTitle}>*/}
      {/*          Основной доход*/}
      {/*        </div>*/}
      {/*        <div className={homeStyles.tooltipText}>*/}
      {/*          Доход облигации без сравнения*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={homeStyles.tooltipTitle}>*/}
      {/*        Разница с депозитом*/}
      {/*      </div>*/}
      {/*      <div className={homeStyles.tooltipText}>*/}
      {/*        Сравнение основного дохода по облигации с доходом по депозиту за похожий срок*/}
      {/*      </div>*/}
      {/*      <div className={homeStyles.tooltipTable}>*/}
      {/*        Депозиты (сроки и доходность):*/}
      {/*        <table>*/}
      {/*          <tbody>*/}
      {/*          {PERIODS.map((period, i) => (*/}
      {/*            <tr key={period}>*/}
      {/*              <td>{PERIODS_RU[i]}</td>*/}
      {/*              <td>{RATES_ALFA[period][1]}%</td>*/}
      {/*            </tr>*/}
      {/*          ))}*/}
      {/*          </tbody>*/}
      {/*        </table>*/}
      {/*      </div>*/}
      {/*    </div>}>*/}
      {/*      <HelpIcon />*/}
      {/*    </Tooltip>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
