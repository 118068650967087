import React, {useState, useEffect, useCallback} from 'react';
import Application from '../../../../modules/Application';
import {DATE_FORMAT} from '../../../../modules/format/formatDate';
import {PERIODS, PERIODS_IN_MONTHS, TERMS} from '../../../../modules/Periods';
import {RATING_GROUPS} from '../../../../modules/Ratings';
import {HomeController} from './HomeController.jsx';
import dayjs from 'dayjs';

const useAbortRequest = (fetch, params = []) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setIsLoading(true);
      setError(null);
      try {
        const fetchData = await fetch(abortController.signal);
        setData(fetchData);
      } catch (error) {
        if (error.name === 'AbortError') {
          /** NB: Aborting a fetch throws an error, So we can't update state afterwards */
          return null;
        }
        setError(error);
        // setData(null);
      } finally {
        setIsLoading(false);
      }
    })();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, params);
  return [data, isLoading, error];
}

export const formatBondsData = (data) => {
  return Object.keys(RATING_GROUPS).reduce((mapByRating, ratingGroup) => {
    mapByRating[ratingGroup] = PERIODS.reduce((mapByPeriod, period, index) => {
      mapByPeriod[period] = data[index].filter((item) => RATING_GROUPS[ratingGroup].includes(item.bond.nationalRating));
      return mapByPeriod;
    }, {})
    return mapByRating;
  }, {})
};

const filterBonds = async (totalAmount, taxRate, signal) => {
  const provider = Application.providerManager.getProvider();
  const ratings = Object.values(RATING_GROUPS).reduce(
    (list, ratings) => {
      list.push(...ratings)
      return list;
    },
    []
  );
  const promises = PERIODS.map((period) => {
    const [termStart, termEnd] = TERMS[period];
    const term = dayjs().add(PERIODS_IN_MONTHS[period], 'M').format(DATE_FORMAT);
    return provider.filterBonds(
      totalAmount,
      term,
      dayjs().add(termStart, 'd').format(DATE_FORMAT),
      dayjs().add(termEnd, 'd').format(DATE_FORMAT),
      taxRate,
      ratings,
      signal
    );
  });
  const response = await Promise.all(promises);
  return formatBondsData(response);
}

const getCalendars = async (signal) => {
  const provider = Application.providerManager.getProvider();
  return await provider.getCalendars(signal);
}

const DEFAULT_TOTAL_AMOUNT = 100000;
export const DEFAULT_TAX_RATE = 0.13;

function HomeContainer() {
  const [totalAmount, setTotalAmount] = useState(DEFAULT_TOTAL_AMOUNT);
  const [taxRate, setTaxRate] = useState(DEFAULT_TAX_RATE);

  const [calendarData, calendarLoading, calendarError] = useAbortRequest(getCalendars, []);
  const [bondsData, bondsLoading, bondsError] = useAbortRequest(
    (signal) => filterBonds(totalAmount, taxRate, signal),
    [totalAmount, taxRate]
  );

  const onTotalAmountChange = useCallback((nextTotalAmount) => {
    if (!isNaN(nextTotalAmount)) {
      setTotalAmount(Number(nextTotalAmount))
    }
  }, []);

  const onTaxRateChange = useCallback((nextTaxRate) => {
    if (!isNaN(nextTaxRate)) {
      setTaxRate(nextTaxRate)
    }
  }, []);

  return (
    <HomeController
      bondsData={bondsData}
      calendarData={calendarData}
      pending={calendarLoading || bondsLoading}
      error={Boolean(calendarError) || Boolean(bondsError)}
      totalAmount={totalAmount}
      taxRate={taxRate}
      onTotalAmountChange={onTotalAmountChange}
      onTaxRateChange={onTaxRateChange}
    />
  );
}

export default HomeContainer;
