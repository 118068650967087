import React, {useState, useEffect, useCallback} from 'react';
import Application from '../../../../modules/Application';
import {DATE_FORMAT} from '../../../../modules/format/formatDate';
import {PERIODS, PERIODS_IN_MONTHS, TERMS} from '../../../../modules/Periods';
import {RATING_GROUPS} from '../../../../modules/Ratings';
import {HomeController} from './HomeController.jsx';
import dayjs from 'dayjs';

export const formatBondsData = (data) => {
  return Object.keys(RATING_GROUPS).reduce((mapByRating, ratingGroup) => {
    mapByRating[ratingGroup] = PERIODS.reduce((mapByPeriod, period, index) => {
      mapByPeriod[period] = data[index].filter((item) => RATING_GROUPS[ratingGroup].includes(item.bond.nationalRating));
      return mapByPeriod;
    }, {})
    return mapByRating;
  }, {})
};

const filterBonds = async (totalAmount, taxRate, signal) => {
  const provider = Application.providerManager.getProvider();
  const ratings = Object.values(RATING_GROUPS).reduce(
    (list, ratings) => {
      list.push(...ratings)
      return list;
    },
    []
  );

  const promises = PERIODS.map((period) => {
    const [termStart, termEnd] = TERMS[period];
    const term = dayjs().add(PERIODS_IN_MONTHS[period], 'M').format(DATE_FORMAT);
    return provider.filterBonds(
      totalAmount,
      term,
      dayjs().add(termStart, 'd').format(DATE_FORMAT),
      dayjs().add(termEnd, 'd').format(DATE_FORMAT),
      taxRate,
      ratings,
      signal
    );
  });
  const response = await Promise.all(promises);
  return {
    success: true,
    data: formatBondsData(response)
  };
}

function HomeContainer() {
  const [totalAmount, setTotalAmount] = useState(100000);
  const [taxRate, setTaxRate] = useState(0.13);
  const [bondsData, setBondsData] = useState(() => {});
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setPending(true);
      setError(false);
      try {
        let result = await filterBonds(
          totalAmount,
          taxRate,
          abortController.signal,
        );
        if (result.success) {
          setPending(false);
          setBondsData(result.data);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // Aborting a fetch throws an error
          // So we can't update state afterwards
          return null;
        }
        setBondsData({});
        setError(true);
        setPending(false);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [totalAmount, taxRate]);

  const onTotalAmountChange = useCallback((nextTotalAmount) => {
    if (!isNaN(nextTotalAmount)) {
      setTotalAmount(Number(nextTotalAmount))
    }
  }, []);

  const onTaxRateChange = useCallback((nextTaxRate) => {
    if (!isNaN(nextTaxRate)) {
      setTaxRate(nextTaxRate)
    }
  }, []);

  return (
    <HomeController
      bondsData={bondsData}
      pending={pending}
      error={error}
      totalAmount={totalAmount}
      taxRate={taxRate}
      onTotalAmountChange={onTotalAmountChange}
      onTaxRateChange={onTaxRateChange}
    />
  );
}

export default HomeContainer;
