import {useState, useMemo} from 'react';
import {Collapse} from '@mui/material';
import classNames from 'classnames';
import {TransitionGroup} from 'react-transition-group';
import {pluralize} from '../../../../../modules/pluralize';
import {PERIODS, PERIODS_RU} from '../../../../../modules/Periods';
import {RATING_GROUPS} from '../../../../../modules/Ratings';
import SuggestionsPopupContainer from './SuggestionsPopupContainer';
import EmptyBondSuggestion from './EmptyBondSuggestion';
import BondSuggestion from './BondSuggestion';
import styles from './styles.module.sass';

export const getSuggestionsCount = (data) => {
  let count = 0;
  Object.values(data).forEach((periodData) => {
    Object.values(periodData).forEach((suggestionsList) => {
      count += suggestionsList.length;
    })
  });
  return count;
}

export const Suggestions = ({data, colorScale, measuredValue}) => {
  const [popup, setPopup] = useState({});
  const suggestionsCount = useMemo(() => getSuggestionsCount(data), [data]);

  const onSuggestionClick = (element, rating, period) => {
    if (popup && popup.rating === rating && popup.period === period) {
      setPopup({});
    } else {
      setPopup({element, rating, period});
    }
  };

  return (
    <>
      <div className={styles.title}>
        {`${suggestionsCount} ${pluralize(suggestionsCount, ['предложение', 'предложения', 'предложений'])} для вас:`}
      </div>
      {data && !!Object.keys(data).length && (
        <>
          <div className={styles.periodAxis}>
            {PERIODS_RU
              .filter((period, i) => Object.keys(data[Object.keys(data)[0]]).includes(PERIODS[i]))
              .map((period) => (
                <div
                  key={period}
                  className={classNames(styles.periodTitle)}
                >
                  {period}
                </div>
              ))}
          </div>
          <TransitionGroup>
            {Object.keys(RATING_GROUPS).reverse().map((rating, i) => data[rating] && (
              <Collapse key={rating}>
                <div className={styles.ratingRowContainer}>
                  <div className={styles.ratingRow}>
                    <div className={classNames(styles.ratingAxisTick, `bgRating${rating.replace('+', 'P')}`)}></div>
                    <div className={styles.suggestionsRow}>
                      {PERIODS.map((period, j) => {
                        if (!data[rating][period]) {
                          return null;
                        }
                        if (data[rating][period].length === 0) {
                          return <EmptyBondSuggestion key={period} />
                        }
                        const bond = data[rating][period][0];
                        return <BondSuggestion
                          key={period}
                          data={data}
                          bond={bond}
                          colorScale={colorScale}
                          period={period}
                          measuredValue={measuredValue}
                          rating={rating}
                          popup={popup}
                          onSuggestionClick={onSuggestionClick}
                      />
                      })}
                    </div>
                  </div>
                  <TransitionGroup>
                    {popup && popup.rating && popup.period && rating === popup.rating && data[popup.rating][popup.period] && (
                      <Collapse>
                        <SuggestionsPopupContainer
                          colorScale={colorScale}
                          parentElement={popup.element}
                          period={popup.period}
                          bonds={data[popup.rating][popup.period]}
                          onClose={() => setPopup({})}
                          measuredValue={measuredValue}
                        />
                      </Collapse>
                    )}
                  </TransitionGroup>
                </div>
              </Collapse>
            ))}
          </TransitionGroup>
        </>
      )}
    </>
  );
};
