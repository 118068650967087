import Slider from '@mui/material/Slider';
import styles from './styles.module.sass';

export const Filter = ({
  title,
  chart,
  value,
  sliderDisabled,
  minValue,
  maxValue,
  onChange,
  sliderValue,
}) => (
  <div className={styles.filter}>
    <div className={styles.title}>{title}</div>
    <div className={styles.chart}>{chart}</div>
    <Slider
      disabled={sliderDisabled}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      step={1}
      marks
      min={minValue}
      max={maxValue}
      valueLabelDisplay={'auto'}
      valueLabelFormat={() => sliderValue}
    />
  </div>
);
