import React from 'react';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom/client';
import {Redirect, Route, Switch, BrowserRouter} from 'react-router-dom';
import Application from './modules/Application';
import reportWebVitals from './reportWebVitals';
import AppVersion1 from './ui/components/App';
import AppVersion2 from './ui/components_v2/App';
import './index.css';

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

Application.init();
Application.providerManager.setToken('a3kb6Daob1Ef2ajQ6sfm7Xs3fO');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route component={AppVersion1} path="/v1" />
        <Route component={AppVersion2} exact path="/"/>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
