import React from 'react';
import {InputAdornment, OutlinedInput} from '@mui/material';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import classNames from 'classnames';
import {CustomNumericFormat} from '../../../common/CustomNumericFormat';
import styles from './TitleFilter.module.sass';
import paramsStyles from '../Params/Params.module.sass';

export const TitleFilter = ({
  totalAmountFromInput,
  setTotalAmountFromInput,
}) => {
  return (
    <div className={styles.titleFilter}>
      <div className={styles.title}>Если вложу</div>
      <div className={classNames(paramsStyles.paramField, paramsStyles.__input, styles.amountInput)}>
        <OutlinedInput
          value={totalAmountFromInput}
          onChange={(e) => setTotalAmountFromInput(e.target.value)}
          size="small"
          endAdornment={<InputAdornment position="end"><CurrencyRubleIcon /></InputAdornment>}
          inputComponent={CustomNumericFormat}
        />
      </div>
      <div className={styles.title}>, заработаю:</div>
    </div>
  );
};
