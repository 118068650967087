import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {Tooltip} from '@mui/material';
import backgroundImage from './girlCut.svg';
import styles from './HowItWorks.module.sass';

const TOOLTIP_TITLE = 'Раздел находится в разработке';

/* eslint-disable jsx-a11y/anchor-is-valid */
export const HowItWorks = ({onClose}) => {
  return (
    <div className={styles.howItWorks}>
      <div
        className={styles.infoBlock}
        style={{backgroundImage: `url(${backgroundImage})`}}
      >
        <div className={styles.title}>
          Сервис подбирает облигации, превосходящие
          <br />
          по доходности банковские депозиты
        </div>
        <div className={styles.bullets}>
          <div className={styles.bullet}>
            <div className={styles.bulletNumber}>1</div>
            <div className={styles.bulletText}>Определитесь с суммой инвестирования</div>
          </div>
          <div className={styles.bullet}>
            <div className={styles.bulletNumber}>2</div>
            <div className={styles.bulletText}>
              Матрица покажет самые выгодные облигации
              <br />
              в зависимости от срока и степени надежности
            </div>
          </div>
          <div className={styles.bullet}>
            <div className={styles.bulletNumber}>3</div>
            <div className={styles.bulletText}>Для опытных инвесторов есть расширенные настройки</div>
          </div>
        </div>
      </div>
      <div className={styles.linksBlock}>
        <div className={styles.linksCol}>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Что такое облигация?</a>
          </Tooltip>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Какие облигации надежны?</a>
          </Tooltip>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Как считается доходность?</a>
          </Tooltip>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Какие могут быть риски?</a>
          </Tooltip>
        </div>
        <div className={styles.linksCol}>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Как купить облигацию?</a>
          </Tooltip>
          <Tooltip title={TOOLTIP_TITLE}>
            <a href="#" className={styles.link}>Можно ли нам доверять?</a>
          </Tooltip>
        </div>
      </div>
      <div className={styles.close} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  );
};
/* eslint-enable jsx-a11y/anchor-is-valid */
