export const RATINGS = ['NR', 'B', 'BB', 'BBB', 'BBB+', 'A', 'AA', 'AAA'];

export const RATING_GROUPS = {
  'AAA': ['AAA'],
  'AA': ['AA+', 'AA', 'AA-'],
  'A': ['A+', 'A', 'A-'],
  'BBB+': ['BBB+'],
  'BBB': ['BBB', 'BBB-'],
  'BB': ['BB+', 'BB', 'BB-'],
  'B': ['B+', 'B', 'B-'],
  'NR': ['NR'],
};

const RISK = {
  low: 'Низкий риск',
  medium: 'Высокий риск'
};

export const getRatingStatusKey = (ratings) => {
  return ratings.includes('NR') || ratings.includes('B') || ratings.includes('BB') || ratings.includes('BBB')
    ? 'medium'
    : 'low'
};

export const getRatingStatus = (ratings) => {
  return RISK[getRatingStatusKey(ratings)];
};
