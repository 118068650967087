export const tokenDecorator = (client, token) =>
  new Proxy(client, {
    get: (target, prop) => {
      if (typeof target[prop] === 'function') {
        return (...args) =>
          target[prop](
            token,
            ...args
          );
      }
      return target[prop];
    },
  });
