import dayjs from 'dayjs';
import {tokenDecorator} from '../data/tokenDecorator';

export class Provider {
  constructor (ApiClient) {
    this._client = ApiClient;
    this._request = tokenDecorator(this._client);
  }

  setToken (token) {
    this._request = tokenDecorator(this._client, token);
  }

  async filterBonds (totalAmount, term, termStart, termEnd, taxRate, ratings, signal) {
    return await this._request.filterBonds(
      totalAmount,
      term,
      termStart,
      termEnd,
      taxRate*100,
      ratings,
      signal
    );
  }

  async getCalendars (signal) {
    const year = dayjs().year();
    return this._request.getCalendars(signal, year);
  }

  async getBondsDaily (isins, attrs) {
    const {today} = await this._request.getSummary();
    return this._request.getBondsDaily(today, isins, attrs);
  }

  async getBondsInfo (isins, attrs) {
    return this._request.getBondsInfo(isins, attrs);
  }
}
