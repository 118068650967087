import React, {useState, useEffect} from 'react';
import Application from '../../../../../modules/Application';
import {SuggestionsPopup} from './SuggestionsPopup.jsx';
import lodash from 'lodash';

const loadBondsInfoData = async (bonds, signal) => {
  const provider = Application.providerManager.getProvider();
  const isins = bonds.map((bond) => bond.isin);
  const [bondsDaily, bondsInfo] = await Promise.all([
    provider.getBondsDaily(isins, ['coupon'], signal),
    provider.getBondsInfo(isins, ['couponPaymentFrequency'], signal),
  ]);
  const bondsInfoMap = lodash.keyBy(bondsInfo, 'isin');
  const bondsDailyMap = lodash.keyBy(bondsDaily, 'isin');
  return {
    success: true,
    data: bonds.reduce((map, bond) => {
      map[bond.isin] = ({
        coupon: bondsDailyMap[bond.isin].data.coupon,
        couponPaymentFrequency: bondsInfoMap[bond.isin].data.couponPaymentFrequency
      })
      return map;
    }, {})
  };
}

function SuggestionsPopupContainer({
 colorScale,
 period,
 bonds,
 onClose,
 parentElement,
 measuredValue
}) {
  const [bondsInfoMap, setBondsInfoMap] = useState(() => {});
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      setPending(true);
      setError(false);
      try {
        let result = await loadBondsInfoData(
          bonds,
          abortController.signal,
        );
        if (result.success) {
          setPending(false);
          setBondsInfoMap(result.data);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          // Aborting a fetch throws an error
          // So we can't update state afterwards
          return null;
        }
        setBondsInfoMap({});
        setError(true);
        setPending(false);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [bonds]);

  return (
    <SuggestionsPopup
      colorScale={colorScale}
      period={period}
      bonds={bonds}
      pending={pending}
      error={error}
      bondsInfoMap={bondsInfoMap}
      onClose={onClose}
      parentElement={parentElement}
      measuredValue={measuredValue}
    />
  );
}

export default SuggestionsPopupContainer;
