import React, { useRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {pluralize} from '../../../../../modules/pluralize';
import LoadingSpinner from '../LoadingSpinner';
import {PERIOD_FULL} from '../Filter/PeriodStatus';
import {BondCard} from './BondCard';
import {SUGGESTION_CONTAINER_CLASSNAME} from './BondSuggestion';
import styles from './SuggestionsPopup.module.sass';
import homeStyles from '../styles.module.sass';

function useOutsideAlerter(ref, parentElement, onClose) {
  /** NB: проверяем наличие css класса у текущего элемента и его родителей */
  function hasParentWithMatchingSelector (target, selector) {
    return [...document.querySelectorAll(selector)].some(el =>
      el !== target && el.contains(target)
    )
  }

  useEffect(() => {
    function handleClickOutside(event) {
      /** NB: закрываем предложения если кликнули вне открытых предложений или в карточку. */
      if (
        ref.current
        && !ref.current.contains(event.target)
        && !parentElement.contains(event.target)
        && event.target.nodeName !== 'HTML'
        && !hasParentWithMatchingSelector(event.target, `.${SUGGESTION_CONTAINER_CLASSNAME}`)
      ) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, parentElement]);
}

export const SuggestionsPopup = ({
  period,
  bonds,
  onClose,
  colorScale,
  pending,
  error,
  bondsInfoMap,
  parentElement,
  measuredValue
}) => {

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, parentElement, onClose);

  return (
    <div className={styles.container} ref={wrapperRef}>
      <div className={styles.close} onClick={onClose}><CloseIcon /></div>
      <div className={styles.title}>{bonds.length} {pluralize(bonds.length, ['облигация', 'облигации', 'облигаций'])}</div>
      <div className={styles.description}>
        Получение полного дохода: {PERIOD_FULL[period].from} — {PERIOD_FULL[period].to}
      </div>
      {error && (
        <div className={homeStyles.messageCover}>
          <div className={homeStyles.messageCover_error}>
            {'Ошибка загрузки данных'}
          </div>
        </div>
      )}
      {pending && (
        <div className={homeStyles.messageCover}>
          <div className={homeStyles.messageCover_caption}>
            {'Загрузка'}
          </div>
          <LoadingSpinner />
        </div>
      )}
      {!pending && !error && (
        <div className={styles.bonds}>
          {bonds.map((bond) => (
            <BondCard
              colorScale={colorScale}
              key={bond.isin}
              bond={bond}
              bondInfo={bondsInfoMap[bond.isin]}
              measuredValue={measuredValue}
            />
          ))}
        </div>
      )}
    </div>
  );
};
