class ProviderManager {
  init ({
    provider
  }) {
    this.provider = provider;
  }

  getProvider () {
    return this.provider;
  }

  setToken (token) {
    this.provider.setToken(token);
  }
}

export default ProviderManager;
