import React from 'react';
import scbInvestLogo from './scb-invest-logo.svg';
import styles from './styles.module.sass';

export const Header = () => (
  <div className={styles.header}>
    <div className={styles.logoContainer}>
      <div className={styles.logo}>
        Skydviser
      </div>
      <div className={styles.logoInfo}>
        Подбор облигаций
      </div>
    </div>
    <div className={styles.companyContainer}>
      <img src={scbInvestLogo} alt="Совкомбанк Инвестиции" />
      <div className={styles.companyInfo}>Платформа Skybonds</div>
    </div>
  </div>
);
