export const PERIODS = ['6M', '9M', '1Y', '1.5Y', '2Y', '3Y'];
export const PERIODS_IN_MONTHS = {
  '3Y': 36,
  '2Y': 24,
  '1.5Y': 18,
  '1Y': 12,
  '9M': 9,
  '6M': 6
};
export const PERIODS_RU = ['6 мес', '9 мес', '1 год', '1,5 года', '2 года', '3 года'];
export const TERMS = {
  "6M": [120, 210], // 180 = 6m
  "9M": [210, 300], // 270 = 9m
  "1Y": [300, 515], // 365 = 1y
  "1.5Y": [515, 700], // 545 = 1.5y
  "2Y": [700, 910], // 730 = 2y
  "3Y": [910, 1275], // 1095 = 3y
}

export const RATES_ALFA = {
  '6M': [181, 7.36],
  '9M': [270, 7.43],
  '1Y': [365, 7.76],
  '1.5Y': [545, 8.47],
  '2Y': [730, 8.64],
  '3Y': [1095, 9.50],
};
