import React from 'react';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import {formatAmount} from '../../../../modules/format';
import styles from './Amount.module.sass';

export const Amount = ({value, prefix = false, postfix = false}) => {
  return (
    <span className={styles.amount}>
      <span>{formatAmount(value, prefix, postfix)}</span>
      <span className={styles.icon}>
        <CurrencyRubleIcon />
      </span>
    </span>
  );
};
