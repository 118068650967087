// eslint-disable-next-line max-classes-per-file
import {Api} from '../api/clients/Api';
import {Provider} from '../api/providers/Provider';
import ProviderManager from './ProviderManager';
import {request} from '../api/data';
import {FullStory} from './FullStory';

class Application {
  constructor () {
    this.providerManager = new ProviderManager();
  }

  init () {
    this.initFullStory();
    this.initProviders();
  }

  // eslint-disable-next-line class-methods-use-this
  initFullStory () {
    FullStory.init();
  }

  initProviders () {
    this.providerManager.init({
      provider: new Provider(
        new Api(request)
      )
    });
  }

  dataReset () {
    this.providerManager = new ProviderManager();
  }
}

const ApplicationInstance = new Application();

export default ApplicationInstance;
