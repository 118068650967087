import classNames from 'classnames';
import styles from './BondSuggestion.module.sass';

const EmptyBondSuggestion = () => {
  return (
    <div
      className={classNames(
        styles.suggestion,
        styles.__empty
      )}
      onClick={() => null}
    >
      <div className={styles.suggestionInfo}>
        <div
          className={styles.suggestionTitle}
        >
          {'Нет предложений'}
        </div>
      </div>

      <div className={styles.suggestionField}>
        {'Мы не можем предложить вам облигации с таким сроком погашения и рейтингом'}
      </div>
    </div>
  );
};

export default EmptyBondSuggestion;
