import React, {useMemo, useRef} from 'react';
import {Tooltip} from '@mui/material';
import classNames from 'classnames';
import styles from './BondSuggestion.module.sass';
import {formatAmount, formatDate, formatPercent} from '../../../../../modules/format';
import {pluralize} from '../../../../../modules/pluralize';
import {PERIODS} from '../../../../../modules/Periods';
import {RATINGS} from '../../../../../modules/Ratings';
import {MEASURED_VALUES_MAP} from '../HomeController';

/** NB: используется для отлова событий */
export const SUGGESTION_CONTAINER_CLASSNAME = 'suggestionContainer';

const BondSuggestion = ({data, bond, colorScale, period, rating, popup, measuredValue, onSuggestionClick}) => {
  const wrapperRef = useRef(null);
  const suggestionsLength = data[rating][period].length;
  const isUnprofitable = useMemo(() => {
    const periodIndex = PERIODS.indexOf(period);
    const ratingIndex = RATINGS.indexOf(rating);
    const peerRating = RATINGS[ratingIndex + 1];
    const peerPeriod = PERIODS[periodIndex - 1];
    return (data[peerRating] && data[peerRating][period] && data[peerRating][period].length && data[peerRating][period][0][measuredValue] > bond[measuredValue]) ||
    (data[rating] && data[rating][peerPeriod] && data[rating][peerPeriod].length && data[rating][peerPeriod][0][measuredValue] > bond[measuredValue])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, measuredValue]);

  const NodeUnit = useMemo(() => {
    if (isUnprofitable) {
      return (({children}) => (
          <Tooltip
            enterDelay={500}
            enterNextDelay={500}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -50],
                  },
                },
              ],
            }}
            title={(
              <div className={styles.isUnprofitableTooltip}>
                Компании в этой карточке приносят меньший доход, несмотря на более высокий риск или длительный срок погашения. Левее или ниже есть предложения лучше
              </div>
            )}>
              {children}
          </Tooltip>
        )
      )
    }
    return (({children}) => <div>{children}</div>);
  }, [isUnprofitable]);

  return (
    <div className={classNames(SUGGESTION_CONTAINER_CLASSNAME, styles.suggestionContainer, {
      [styles.__active]: popup && popup.rating === rating && popup.period === period,
    })}>
      <NodeUnit>
        <div
          key={period}
          style={{background: colorScale(bond[measuredValue])}}
          className={classNames(
            styles.suggestion,
            {
              [styles.__active]: popup && popup.rating === rating && popup.period === period,
              [styles.__unprofitable]: isUnprofitable,
            }
          )}
          onClick={() => onSuggestionClick(wrapperRef.current, rating, period)}
          ref={wrapperRef}
        >
          <div className={styles.suggestionInfo}>
            {suggestionsLength + ' ' + pluralize(suggestionsLength, ['компания', 'компании', 'компаний'])}
          </div>
          {measuredValue === MEASURED_VALUES_MAP.INCOME && (<div>
            <div>
              Наибольший доход
            </div>
            <div className={styles.suggestionIncome} title={formatAmount(bond[measuredValue])}>
              {formatAmount(bond[measuredValue])}
            </div>
          </div>)}
          {measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT && (<div>
            <div>
              Наибольшая разница с депозитом
            </div>
            <div className={styles.suggestionIncome} title={formatAmount(bond[measuredValue], true)}>
              {formatAmount(bond[measuredValue], true)}
            </div>
          </div>)}

          <div
            className={styles.suggestionTitle}
            title={bond.issuerName}
          >
            {bond.issuerName}
          </div>
          <div className={styles.suggestionField}>
            <div className={styles.suggestionFieldTitle}>Доходность</div>
            <div className={styles.suggestionFieldValue} title={formatPercent(bond.yield)}>
              {formatPercent(bond.yield)}
            </div>
          </div>
          <div className={styles.suggestionField}>
            <div className={styles.suggestionFieldTitle}>Погашение</div>
            <div className={styles.suggestionFieldValue} title={formatDate(bond.redemptionDate)}>
              {formatDate(bond.redemptionDate)}
            </div>
          </div>
          <div className={styles.suggestionField}>
            <div className={styles.suggestionFieldTitle}>Результат</div>
            <div className={styles.suggestionFieldValue} title={formatAmount(bond.totalResult)}>
              {formatAmount(bond.totalResult)}
            </div>
          </div>
          <div className={styles.suggestionRating}>{bond.nationalRating}</div>
        </div>
      </NodeUnit>
    </div>
  );
};

export default BondSuggestion;
