export const getUrlWithQuery = (url, query) => {
  const queryStr = Object.keys(query)
    .filter((key) => query[key] !== null)
    .map((key) => {
      const value = Array.isArray(query[key])
        ? query[key].map((item) => encodeURIComponent(item))
        : encodeURIComponent(query[key]);

      return `${encodeURIComponent(key)}=${value}`;
    })
    .join('&');

  if (queryStr.length === 0) {
    return url;
  }

  return url.includes('?')
    ? `${url}&${queryStr}`
    : `${url}?${queryStr}`;
};
