import React from 'react';
import {NumericFormat} from 'react-number-format';

/** Маска для разбиения числа по разрядам: `100000 -> 100 000` */
export const CustomNumericFormat = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        // console.log( values.value)
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      thousandSeparator=" "
    />
  );
});
