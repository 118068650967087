import classNames from 'classnames';
import {useMemo} from 'react';
import {formatAmount, formatPercent} from '../../../../../modules/format';
import {getDateDiff} from '../../../../../modules/format/formatDate';
import {pluralize} from '../../../../../modules/pluralize';
import styles from './BondCard.module.sass';
import {MEASURED_VALUES_MAP} from "../HomeController";

export const BondCard = ({bond, bondInfo, colorScale, measuredValue}) => {
  const ratingTitle = useMemo(() => {
    switch (bond.nationalRating) {
      case 'NR':
      case 'B-':
      case 'B':
      case 'B+':
      case 'BB-':
      case 'BB':
      case 'BB+':
      case 'BBB-':
      case 'BBB': {
        return 'Высокий риск';
      }
      case 'BBB+':
      case 'A-':
      case 'A':
      case 'A+':
      case 'AA-':
      case 'AA':
      case 'AA+':
      case 'AAA': {
        return 'Низкий риск';
      }
      default:
        return '';
    }
  }, [bond]);

  const table = useMemo(() => {
    const isHasCoupon = bondInfo && bondInfo.coupon && bondInfo.couponPaymentFrequency;
    const couponYield = `${bondInfo?.coupon}%`;
    const couponFrequency = `${bondInfo?.couponPaymentFrequency} ${pluralize(bondInfo?.couponPaymentFrequency, ['раз', 'раза', 'раз'])} в год`;
    return [
      {
        label: 'Купон',
        value: isHasCoupon ? couponYield : null,
        hint: isHasCoupon ? couponYield : null,
      },
      {
        label: 'Частота выплат',
        value: isHasCoupon ? couponFrequency : null,
        hint: isHasCoupon ? couponFrequency : null,
      },
      {
        label: 'Цена покупки',
        value: formatPercent(bond.buyPrice),
        hint: formatPercent(bond.buyPrice),
      },
      {
        label: 'Количество',
        value: `${bond.buyQuantity} шт.`,
        hint: `${bond.buyQuantity} шт.`,
      },
      {
        label: 'Надёжность',
        value: (
          <div className={styles.rating}>
            <div className={classNames(styles.ratingStatus, `bgRating${bond.ratingGroup.replace('+', 'P')}`)}>
              {bond.nationalRating}
            </div>
            {ratingTitle}
          </div>
        ),
        hint: ratingTitle,
      },
      {
        label: 'Сектор',
        value: bond.sector,
        hint: bond.sector,
      },
      {
        label: 'ISIN',
        value: bond.isin,
        hint: bond.isin
      },
    ].filter((item) => item.value);
  }, [bond, bondInfo]);

  return (
    <div
      className={styles.container}
      style={{background: colorScale(bond[measuredValue])}}
    >
      <div className={styles.title}>
        <div className={styles.titleName} title={bond.issuerName}>{bond.issuerName}</div>
        <div className={styles.titleNumber}>{bond.issueNumber}</div>
      </div>
      <div className={styles.text}>
        {measuredValue === MEASURED_VALUES_MAP.INCOME ? 'Основной доход' : 'Разница с депозитом'}
      </div>
      <div className={styles.differenceWithDeposit}>
        {formatAmount(bond[measuredValue], Number(bond[measuredValue]) < 0 || measuredValue === MEASURED_VALUES_MAP.DIFFERENCE_WITH_DEPOSIT)}
      </div>
      <div className={styles.result}>
        <div className={styles.resultTitle}>
          Результат за {getDateDiff(bond.redemptionDate)}<span className={styles.resultTitle_proposal}>{bond.proposalType === 'put' ? ' (оферта)' : ''}</span>:
        </div>
        <div className={styles.resultField}>
          <div className={styles.resultBalloon} />
          <div
            className={styles.resultProgress}
            style={{
              width: bond.depositTotal > bond.totalResult ? `${bond.totalResult / bond.depositTotal * 100}%` : '100%',
            }}
          />
          <div className={styles.resultDescription}>
            Облигация
          </div>
          <div className={styles.resultAmount}>
            {formatAmount(bond.totalResult)}
            <span>{formatPercent(bond.yield)}</span>
          </div>
        </div>
        <div className={styles.resultField}>
          <div className={styles.resultBalloon} />
          <div
            className={styles.resultProgress}
            style={{
              width: bond.totalResult > bond.depositTotal ? `${bond.depositTotal / bond.totalResult * 100}%` : '100%',
            }}
          />
          <div className={styles.resultDescription}>
            Депозит
            <span>
              {bond.periodLabel.replace('мес', 'м').replace('года', 'г').replace('год', 'г').replace(' ', '')}
            </span>
          </div>
          <div className={styles.resultAmount}>
            {formatAmount(bond.depositTotal)}
            <span>{formatPercent(bond.depositYield)}</span>
          </div>
        </div>
      </div>
      <div className={styles.table}>
        {table.map((field) => (
          <div key={field.label} className={styles.field}>
            <div>{field.label}</div>
            <div className={field.label === 'Надёжность' ? styles.ratingField : ''} title={field.hint}>{field.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
